import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BankOutlined,
  BuildOutlined,
  BulbOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
  FileImageOutlined,
  FileOutlined,
  FormOutlined,
  FormatPainterOutlined,
  FundProjectionScreenOutlined,
  GiftOutlined,
  HeartOutlined,
  HomeOutlined,
  LineChartOutlined,
  MenuOutlined,
  SolutionOutlined,
  TranslationOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Layout, Menu, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import longLogo from '../assets/long-logo-sm.png';
import useCheckCCCreds from '../pages/settings/use-check-cc-creds';
import useProfile from '../shared/use-profile';

const { Sider } = Layout;
const { Title } = Typography;

function MainMenu({ currentItem, onMenuClick }) {
  const profile = useProfile();
  const permissions = profile?.permissions;
  const showUsers = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'create-admin-user');
  }, [permissions]);
  const showAccounting = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-account');
  }, [permissions]);
  const showProjects = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-h-project');
  }, [permissions]);
  const showPublicForms = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-p-form');
  }, [permissions]);
  const showTraining = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-training-course');
  }, [permissions]);
  const showAdoptions = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-adoption');
  }, [permissions]);
  const showUpgQuarterlyReports = useMemo(() => {
    return permissions?.find(
      (p) => p.prefix === 'list-admin-upg-quarterly-report',
    );
  }, [permissions]);
  const showImages = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'list-admin-saved-image');
  }, [permissions]);

  useCheckCCCreds(permissions);

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[currentItem]}
      onClick={onMenuClick}
      className="main-nav-menu"
    >
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to="/home">Home</Link>
      </Menu.Item>
      {showUsers && (
        <Menu.Item key="users" icon={<UsergroupAddOutlined />}>
          <Link to="/users">Users</Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.SubMenu key="sub1" icon={<ContactsOutlined />} title="CRM">
        <Menu.Item key="people" icon={<ContactsOutlined />}>
          <Link to="/people">People</Link>
        </Menu.Item>
        <Menu.Item key="events" icon={<CalendarOutlined />}>
          <Link to="/events">Events</Link>
        </Menu.Item>
        <Menu.Item key="tasks" icon={<CheckSquareOutlined />}>
          <Link to="/tasks">Tasks</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Divider />
      {showAccounting && (
        <>
          <Menu.SubMenu
            key="sub2"
            icon={<DollarCircleOutlined />}
            title="Accounting"
          >
            <Menu.Item key="pushpay" icon={<DollarCircleOutlined />}>
              <Link to="/pushpay/donations">Pushpay</Link>
            </Menu.Item>
            <Menu.Item key="accounting" icon={<BankOutlined />}>
              <Link to="/accounting/accounts">Accounts</Link>
            </Menu.Item>
            <Menu.Item key="payouts" icon={<CarryOutOutlined />}>
              <Link to="/payouts">Payouts</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Divider />
        </>
      )}
      <Menu.SubMenu
        key="sub3"
        icon={<TranslationOutlined />}
        title="Unreached People Groups"
      >
        <Menu.Item key="people-groups" icon={<TranslationOutlined />}>
          <Link to="/people-groups">Unreached People Groups</Link>
        </Menu.Item>
        {showAdoptions && (
          <>
            <Menu.Item key="adoptions" icon={<GiftOutlined />}>
              <Link to="/adoptions">UPG Sponsorships</Link>
            </Menu.Item>
            <Menu.Item key="upg-prayer-adoptions" icon={<HeartOutlined />}>
              <Link to="/upg-prayer-adoptions">UPG Prayer Adoptions</Link>
            </Menu.Item>
            <Menu.Item key="mous" icon={<SolutionOutlined />}>
              <Link to="/mous">Partnership Commitment Forms</Link>
            </Menu.Item>
            <Menu.Item key="mou-templates" icon={<BuildOutlined />}>
              <Link to="/mou-templates">
                Partnership Commitment Form Templates
              </Link>
            </Menu.Item>
          </>
        )}
        {showUpgQuarterlyReports && (
          <Menu.Item key="upg-quarterly-reports" icon={<FormOutlined />}>
            <Link to="/upg-quarterly-reports/approved">
              UPG Quarterly Reports
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="adoption-stats" icon={<LineChartOutlined />}>
          <Link to="/adoption-stats">UPG Sponsorships Stats</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Divider />
      {showProjects && (
        <>
          <Menu.SubMenu
            key="sub4"
            icon={<FormatPainterOutlined />}
            title="Holistic Projects"
          >
            <Menu.Item key="holistic-projects" icon={<FormatPainterOutlined />}>
              <Link to="/holistic-projects">Holistic Projects</Link>
            </Menu.Item>
            <Menu.Item
              key="holistic-project-proposals"
              icon={<FundProjectionScreenOutlined />}
            >
              <Link to="/holistic-proposals">Holistic Project Proposals</Link>
            </Menu.Item>
            <Menu.Item key="holistic-quarterly-reports" icon={<FormOutlined />}>
              <Link to="/holistic-quarterly-reports/pending">
                Holistic Project Quarterly Reports
              </Link>
            </Menu.Item>
            <Menu.Item key="loan-agreement-templates" icon={<BuildOutlined />}>
              <Link to="/loan-agreement-templates">
                Loan Agreement Templates
              </Link>
            </Menu.Item>
            <Menu.Item key="project-sponsorships" icon={<GiftOutlined />}>
              <Link to="/project-sponsorships">
                Holistic Project Partnerships
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Divider />
        </>
      )}
      {showTraining && (
        <Menu.SubMenu key="sub5" icon={<BulbOutlined />} title="Training">
          <Menu.Item key="training-event-reports" icon={<CalendarOutlined />}>
            <Link to="/training-event-reports/pending">
              Training Event Reports
            </Link>
          </Menu.Item>
          <Menu.Item key="training-quarterly-reports" icon={<FormOutlined />}>
            <Link to="/training-quarterly-reports/pending">
              Training Quarterly Reports
            </Link>
          </Menu.Item>
          <Menu.Item key="training-courses" icon={<BulbOutlined />}>
            <Link to="/training-courses">Training Courses</Link>
          </Menu.Item>
          <Menu.Item key="training-files" icon={<FileOutlined />}>
            <Link to="/training-files">Training Files</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      {showImages && (
        <Menu.Item key="saved-images" icon={<FileImageOutlined />}>
          <Link to="/saved-images">Saved Images</Link>
        </Menu.Item>
      )}
      {showPublicForms && (
        <Menu.Item key="public-forms" icon={<FormOutlined />}>
          <Link to="/public-forms">Public Forms</Link>
        </Menu.Item>
      )}
      {(showTraining || showPublicForms) && <Menu.Divider />}
      <Menu.Item key="prayer" icon={<ArrowUpOutlined />}>
        <Link to="/prayer">Prayer Requests</Link>
      </Menu.Item>
      <Menu.Item key="miracles" icon={<ArrowDownOutlined />}>
        <Link to="/miracles">Testimonies</Link>
      </Menu.Item>
    </Menu>
  );
}

function MainNavMenu() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const currentItem = useMemo(() => {
    const path = location.pathname;
    if (path === '/home') {
      return 'home';
    }
    return path;
  }, [location]);
  const onMenuClick = useCallback(({ item, key, keyPath, domEvent }) => {}, []);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  if (responsiveMode === 'mobile') {
    return (
      <>
        <Layout.Header
          className="main-nav-header"
          style={{ position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <img className="logo-img" src={logo} alt="AIMS Logo" />
          <Button
            key="1"
            type="primary"
            icon={<MenuOutlined onClick={() => setDrawerVisible(true)} />}
          />
        </Layout.Header>
        <div style={{ height: 72 }} />
        <Drawer
          title="AIMS Admin"
          placement="right"
          closable={true}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          className="main-nav-drawer"
        >
          <MainMenu onMenuClick={onMenuClick} currentItem={currentItem} />
        </Drawer>
        <style jsx global>{`
          .logo-img {
            height: 56px;
            margin-top: 12px;
            margin-bottom: 12px;
            margin-left: 16px;
          }
          .main-nav-menu {
            height: calc(100% - 80px);
          }
          .main-nav-menu-slider .ant-layout-sider-trigger {
            border-right: 1px solid #f0f0f0;
          }
          .main-nav-header {
            background-color: black;
            height: unset;
            padding-left: 0;
            padding-right: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .main-nav-drawer .ant-drawer-body {
            padding: 0px;
          }
        `}</style>
      </>
    );
  }
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={80}
      onBreakpoint={(broken) => {
        setCollapsed(collapsed || broken);
      }}
      onCollapse={(_collapsed, type) => {
        if (type === 'clickTrigger') {
          setCollapsed(_collapsed);
        }
      }}
      theme="light"
      width={300}
      collapsible
      collapsed={collapsed}
      className="main-nav-menu-slider"
    >
      <div className="logo" style={{ width: collapsed ? 80 : undefined }}>
        {!collapsed && (
          <img className="logo-img" src={longLogo} alt="Freeda Logo" />
        )}
        {collapsed && (
          <img className="square-logo-img" src={logo} alt="Freeda Logo" />
        )}
      </div>
      <MainMenu onMenuClick={onMenuClick} currentItem={currentItem} />
      <style jsx>{`
        .sider {
          width: 300px;
          background: white;
        }
        .logo {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #f0f0f0;
        }
        .square-logo-img {
          height: 56px;
          padding: 16px;
        }
        .logo-img {
          height: 56px;
        }
        .spacer {
          margin: auto;
        }
      `}</style>
    </Sider>
  );
}

export default MainNavMenu;
